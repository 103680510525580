












































import Vue from 'vue'

import axios from 'axios'

import { HBtn, HPopin, HRow, HCol, HFormField, HInput } from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HBtn,
        HPopin,
        HRow,
        HCol,
        HFormField,
        HInput,
    },

    data() {
        return {
            telephone: '',
            errorPhoneNumber: false,
            status: '',
            popinModel: false
        }
    },

    computed: {
        btnLabel(): string {
          return this.$breakpoint.smAndDown ? '' : 'Contactez-nous'
        }
    },

    methods: {
        onClickHelpBtn(): void {
            this.popinModel = true
        },
        async onClickSubmitBtn(): Promise<void> {
            if (this.telephone.length < 14){
                this.errorPhoneNumber = true
            } else {
                const phoneNumber = '33'+this.telephone.replace(/\s/g, '').slice(1)
                this.errorPhoneNumber = false
                try {
                    await this.postToRingover( parseInt(phoneNumber) )
                    this.status = '200'
                } catch {
                    this.status = 'error'
                }
            }
        },
        postToRingover(number: number): Promise<void> {
            return axios.post(
                'https://public-api.ringover.com/v2/ivrs/30312/callback',
                {
                    "from_number": 33187666927,
                    "clir": false,
                    "to_number": number,
                    "timeout": 45,
                },
                {
                    headers: {
                        Authorization: "4b2f2dabd48569935b6cf0c6ba33a06d108b39be"
                    }
                }
            )
        },
        resetForm(): void {
            this.telephone = ''
            this.errorPhoneNumber = false
            this.status = ''
        }
    },
})

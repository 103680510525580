
<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
      {{ theTitle }}
        <vs-spacer />
        <BtnHelpCallback v-if="displayBtnHelp" class="btn-help" />
      </vs-navbar>
    </div>
  </div>
</template>


<script>
import BtnHelpCallback from '@/components/BtnHelpCallback.vue'

export default {
  name: 'the-navbar-vertical-public',

  props: {
    theTitle: {
      type: String,
      required: true
    },
    navbarColor: {
      type: String,
      default: '#fff'
    },
    displayBtnHelp: Boolean,
  },
  components: {
    BtnHelpCallback,
  },

  computed: {
    navbarColorLocal () {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor () {
      return {'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    classObj () {
      if      (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth)               return 'navbar-full'
      return ''
    }
  },
  methods: {
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },
  }
}
</script>

<style>
  .curser-pointer {
    cursor: pointer;
  }
</style>

import { render, staticRenderFns } from "./ForgotPasswordExpired.vue?vue&type=template&id=587f8dde&scoped=true&"
import script from "./ForgotPasswordExpired.vue?vue&type=script&lang=js&"
export * from "./ForgotPasswordExpired.vue?vue&type=script&lang=js&"
import style0 from "./ForgotPasswordExpired.vue?vue&type=style&index=0&id=587f8dde&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587f8dde",
  null
  
)

export default component.exports